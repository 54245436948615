export default {
	props: {
		component: {
			type: Object,
			default: () => {
				return {
					content: {},
					template: {},
					schema: {},
					metadata: {},
				};
			},
		},
		dark: {
			type: Boolean,
			default: false,
		},
		theme: {
			type: String,
			default: "white",
		},
		editable: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		content() {
			if (this.component && this.component.content) return this.component.content;
			return {
				sausage: undefined,
			};
		},
		metadata() {
			if (this.component && this.component.metadata) return this.component.metadata;
			return {};
		},
		template() {
			if (this.component && this.component.template) return this.component.template;
			return {};
		},
	},
};
